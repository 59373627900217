import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,

  routes: [{
    path: "/",
    redirect: "/home",
    component: () => import("@/layouts/Blanklayout"),
    children: [{
      name: "home",
      path: "home",
     // component: () => import("@/views/HomeView"),
      component: () => import("@/views/newLoad"),
      meta: {
        requiresAuthNot: true
      },
    },
    {
      path: '/venues',
      name: 'venues',
      component: () => import("@/views/VenuePage.vue"),
      meta: {
        requiresAuthNot: true
      },
    },
    {
      path: '/blogs',
      name: 'Blogs',
      component: () => import("@/views/blogS.vue"),
      meta: {
        requiresAuthNot: true
      },
    },
    {
      path: '/blog-details/:id',
      name: 'Blogs Details',
      component: () => import("@/views/blogDetails.vue"),
      meta: {
        requiresAuthNot: true
      },
    },
    {
      path: '/venue-details/:id',
      name: 'venue-details',
      component: () => import("@/views/VenueDetail.vue"),
      meta: {
        requiresAuthNot: true
      },
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import("@/views/ContactPage.vue"),
      meta: {
        requiresAuthNot: true
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import("@/views/LoginPage.vue"),
    },
    {
      path: '/signup',
      name: 'signup',
      component: () => import("@/views/SignupPage.vue"),
    },
    {
      path: '/forgotpassword',
      name: 'ForgotPass',
      component: () => import("@/views/ForgotPassword.vue"),
    },
    {
      path: '/resetlink',
      name: 'resetlink',
      component: () => import("@/views/ResetLink.vue"),
    },
    {
      path: '/reset-password/:token',
      name: 'resetpassword',
      component: () => import("@/views/ResetPassword.vue"),
    },
    {
      path: '/error',
      name: 'error',
      component: () => import("@/views/ErrorPage.vue"),
    },
    {
      path: '/expire-link',
      name: 'expire-link',
      component: () => import("@/views/ExpireLink.vue"),
    },
    {
      path: '/account-verified/:token',
      name: 'account-verify',
      component: () => import("@/views/AccountVerify.vue"),
    },
    {
      path: '/account-verification',
      name: 'account-verification',
      component: () => import("@/views/AfterRegister.vue"),
    },
    {
      path: '/VenuebookingMap',
      name: 'venue-booking-map',
      component: () => import("@/views/VenuebookingMap.vue"),
      meta: {
        requiresAuthNot: true
      },
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import("@/views/ProfilePage.vue"),
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/profile/personal-detail',
      name: 'personal-detail',
      component: () => import("@/views/PersonalDetail.vue"),
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/checkout/:id',
      name: 'checkout',
      component: () => import("@/views/CheckOut.vue"),
      meta: {
        requiresAuthNot: true
      },
    },
    {
      path: '/checkout-success',
      name: 'checkout-success',
      component: () => import("@/views/CheckoutConfirm.vue"),
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/welcome',
      name: 'welcome',
      component: () => import("@/views/Demo.vue"),
      meta: {
        requiresAuth: true
      },
    },
    {
      path: "/profile/my-booking",
      name: "mybooking",
      component: () => import("@/views/MyBookings.vue"),
      meta: {
        requiresAuth: true
      },
    },
    ],

  },],
});

router.beforeResolve((to, from, next) => {

  document.title = process.env.VUE_APP_NAME;
  window.scrollTo(0, 0);
  if (!to.name) {
    if (localStorage.getItem('authenticated')) {

      next();
    } else {

      localStorage.clear();
      next({ name: 'home' })
    }
  }

  if (to.matched.some(record => record.meta.requiresAuthNot)) {
    if (localStorage.getItem('authenticated')) {
      next();
    } else {
      next();
    }
  }

  else if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('authenticated')) {
      next();
    } else {
      localStorage.clear();
      next({ name: 'home' })
    }
  }
  else {
    if (localStorage.getItem('authenticated')) {
      if (to.name == 'account-verify') {
        next()
      } else {
        next({ name: 'home' })
      }

    }
    else {
      next()
    }
  }

});


router.afterEach(() => {
  // Complete the animation of the route progress bar.

});

export default router;
